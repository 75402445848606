// Init svgMap.js
// es6
//
// uses:
// - https://github.com/StephanWagner/svgMap

function initSvgMap() {
  if (typeof window.svgMap === 'undefined' || window.svgMap === null) {
    return;
  }

  const maps = document.querySelectorAll('[data-svgmap-element]');

  if (maps.length) {
    maps.forEach((el) => {
      // fetch data source
      const source = document.querySelector(
        '[data-svgmap-source=' + el.id + ']'
      );
      if (!source) {
        return;
      }

      // build data
      const countries = source.querySelectorAll('[data-svgmap-target]');
      if (countries.length == 0) {
        return;
      }

      // empty contents
      while (el.firstChild) {
        el.removeChild(el.firstChild);
      }

      // create map
      const map = new svgMap({
        targetElementID: el.id,
        mouseWheelZoomEnabled: false,
        mouseWheelZoomWithKey: true,
        hideFlag: true,
        initialZoom: 1.2,
        minZoom: 1,
        maxZoom: 6,
        noDataText: '',
        colorMax: '#657A7D',
        colorMin: '#657A7D',
        onGetTooltip: function (tooltipDiv, countryID, countryValues) {
          if (countryValues && countryValues.tooltip) {
            return countryValues.tooltip.content.cloneNode(true);
          }
          return false;
        },
        data: {
          data: {
            presence: {
              name: 'Presence',
            },
          },
          applyData: 'presence',
          values: Object.fromEntries(
            Array.from(countries).map((e) => [
              e.dataset.svgmapTarget,
              {
                presence: true,
                tooltip: e.querySelector('[data-svgmap-tooltip]'),
              },
            ])
          ),
        },
      });

      // finish loading
      el.classList.remove('lazyloading');
      el.classList.add('lazyloaded');
    });
  }
}

initSvgMap();
document.addEventListener('pjax:success', initSvgMap);
